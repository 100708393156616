@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Gochi Hand", cursive;
    cursor: url('./assets/images/hand1.svg'),auto;
    background-color: #1EA0FF;
    scrollbar-width: none; /* Firefox */
    color: white;
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-size: 1.2vw;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    color: inherit;
    text-decoration: none;
}


.no_margin{
    margin: 0;
}
.background{
    position: absolute;
    width:100vw;
    height: 40rem;
    overflow: hidden;
}

.cloud1 {
    position: absolute;
    width : 25rem;
    top: -7.5rem;
    left: -2.1rem;
}

.cloud2 {
    position: absolute;
    width : 20rem;
    right: -2rem;
    top: -4rem;
}

.cloud3 {
    position: absolute;
    width : 15rem;
    left: -8rem;
    top:11rem;
}

.cloud4 {
    position: absolute;
    width : 15rem;
    right: -1rem;
    top:10rem;
}

.contentCloud{
    position: absolute;
    width : 30rem;
}
.contentCloud1{
    right: 0;
    transform: translateY(30rem);
}
.contentCloud2{
    left: -6rem;
    transform: translateY(108rem);
}
.contentCloud3{
    left: 8rem;
    transform: translateY(145rem);
}
.contentCloud4{
    right: 0;
    transform: translateY(193rem);
}
.contentCloud5{
    /*display: none;*/
    /*buy cloud*/
    /*left: 20rem;*/
    /*width: 43rem;*/
    /*transform: translateY(243rem);*/
}

.contentCloud6{
    left: 8rem;
    transform: translateY(290rem);
}
.container{
    position:relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.header {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.moni_title {
    width: 23rem;
}

.moni_pointer {
    cursor: url('./assets/images/hand2.svg'),auto;
}

.sub_title {
    margin-top: 1rem;
    font-size: 4rem;
    font-weight: 100;
    -webkit-text-stroke: 0.15rem #88E5ED;
    text-shadow: 0.15rem 0.15rem 0.5rem #0168c5;

}

.icon_box {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.3rem;
}

.icon {
    width: 2.5rem;
}

.moni_container {
    position: relative;
    display: inline-block;
}



.moni_text {
    text-align: left;
    position: absolute;
    top: 60%;
    left: 133%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 1.4rem;
    color: #000000;
    font-weight: 600;
    font-family: 'Fuzzy Bubbles', cursive;
}
.moni {
    width: 16rem;
    display: inline-block;
}

.menu {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.menu a img {
    width: 13rem;
    height: auto;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin: 4rem 0;
    width: 60rem;
}

.section {
    position: relative;
    width: 100%;
    max-width: 90rem;
    margin: 2rem auto;
}

.about {
    width: 100%;
    height: auto;
}
.about>img{
    width: 100%;
}

.hidden_text {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.token_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    margin: 4rem 0;
    width: 100%;
}

.token_title {
    font-family: 'Gochi Hand';
    font-size: 9rem;
    font-weight: 400;
    margin:  0  0  0 5rem;
}

.learn_more{
    margin: 0;
    font-size: 2.6rem;

}
.moni_teach_web{
    position: relative;
    width: 100%;
}
.moni_teach_web> img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
    align-self: center;
}
.moni_teach_content{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 25rem;
    top: 5rem;
    left: 3rem;

}
.moni_teach_content>p{
    font-size: 2rem;
    margin-left: 2.8rem;
}

.chart {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.chart_div{
    transform: translate(0.8rem,-2rem);
    position: absolute;
    margin: 0;
    top: 5rem;
}
.chart_div p{
    font-size: 5rem;
    margin: 0;
    color: black;
}
.birdeye_chart{
    display: flex;
    flex-direction: column;
    width: 35.5rem;
    align-items: center;
    justify-content: center;
}
.birdeye_chart img{
    width: 100%;
}
.chart_api{
    width: 100%;
    height: 26rem;
}
.partners{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.partners h1{
    font-size: 7rem;
    font-weight: 500;
    color:black;
    margin: 0;
}
.partners_div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
}

.partners_div a{
    width: 50%
}
.partners_img{
    width: 100%;
}
.swap{

}
.swap img{
    width: 30rem;
    margin: 0 0 3rem 0 ;
}
.swap p{
    font-size: 7rem;
    margin: 0 0 1.5rem 0 ;
    color: black;
}

#integrated-terminal{
    position: relative;
    width: 20rem;
    height: 100%;
    background: none;
    padding: 0;
}
.chart img {

    max-width: 100%;
    height: auto;
}

.network {

}
.game{

}
.game img{
    width: 80%;
}
.network_div_web{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;


}
.moni_phone{
    width: 40rem;
    margin-right: 2rem;
}


.network_title {
    font-family: 'Gochi Hand', serif;
    font-weight: 400;
    font-size: 6rem;
    margin:0;
    color: black;
}

.meme {
    margin: 3rem 0;
    text-align: center;
}
.network_item{
    position: absolute;
    height: 21.41rem;
    left: 23.5rem;
    top: 6.3rem;
    display: flex;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    flex-direction: column;
}
.network_item_row {

    display: flex;
    flex-direction: row;
    width: 18rem;
    margin-bottom: 1rem;
    gap: 1rem;
}
.network_item_content{
    position: relative;
    width: 25%;
}
.network_item_content  img{
    border-radius: 0.7rem;
    width: 100%;
    height: auto;
}

.meme_title {
    font-family: 'Gochi Hand';
    font-size: 9rem;
    font-weight: 400;
    margin: 0;
    color: black;
}
.game_title {
    font-family: 'Gochi Hand';
    font-size: 8rem;
    font-weight: 400;
    margin: 0;
    color: black;
}
.meme_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    max-height: 700px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    margin: 2rem 0 2rem 0;
}

.meme_grid img {
    width:100% ;
    height: 100% ;
    object-fit: cover;
}

.footer {
    width: 100%;
    background-image: url('./assets/images/footer_background.png');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 5rem 0 1rem 0;
    text-align: center;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.footer_div {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 200%;
    height: 8rem;
    -webkit-animation: marquee 20s linear infinite;
    animation: marquee 20s linear infinite;
}

.footer_txt{
    float: left;
    align-items: center;
    margin: 3rem 0 0 0;
    color: #62A460;
    font-family: 'Gochi Hand', cursive;
    font-size: 2rem;
}

.moni_video{
    position: absolute;
    top: 15rem;
    left: 8rem;
    width: 43rem;
}

.post {
    text-align: center;
    margin: 4rem 0;
  }
  
  .post h1 {
    font-family: 'Gochi Hand', cursive;
    font-size: 4rem;
    color: black;
    margin-bottom: 2rem;
  }
  
  .twitter-hashtag-button {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    color: #ffffff;
    background-color: #1da1f2;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .twitter-hashtag-button:hover {
    background-color: #1a91da;
  }
  

@-webkit-keyframes marquee {
    100% { left: 0; }
    0% { left: -99%; }
}

@keyframes marquee {
    100% { left: 0; }
    0% { left: -99%; }
}

@media (min-width: 1800px) {
    html{
        font-size: 0.7vw;
    }
    .sky {
        height: 42rem;
        object-position: bottom;
    }
    .footer {
        width: 100%;
        background-image: url('./assets/images/footer_background.png');
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        padding: 5rem 0 1rem 0;
        text-align: center;
        height: 11rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: visible;
    }
    .footer_txt{
        float: left;
        white-space: nowrap;
        align-items: center;
        margin: 3rem 0 0 0;
        color: #62A460;
        font-family: 'Gochi Hand', cursive;
        font-size: 5rem;
    }
}

@media (min-width: 901px) {
    .about_bg_web{
        display: block;
    }
    .moni_teach_web{
        display: block;
    }
    .network_div_web{
        display: flex;
    }
    .network_div_mobile{
        display: none;
    }
    .about_bg_mobile{
        display: none;
    }
    .moni_teach_mobile{
        display: none;
    }
    .token_title {
        text-align: left;
    }
}

@media (max-width: 900px) {
    html{
        font-size: 2vw;
    }
    .sky {
        height: 40rem;
        object-position: bottom;
    }
    .moni_title {
        width: 30rem;
    }
    .sub_title {
        margin-top: 1rem;
        font-size: 6.5rem;
        font-weight: 100;
        -webkit-text-stroke: 0.15rem #88E5ED;
        text-shadow: 0.15rem 0.15rem 0.5rem #0168c5;

    }
    .icon {
        width: 4rem;
    }
    .moni {
        width: 18rem;
        display: inline-block;
    }
    .moni_text {
        position: absolute;
        top: 50%;
        left: 130%;
        transform: translate(-70%, 30%);
        white-space: nowrap;
        font-size: 3rem;
        color: #000000;
        font-weight: 600;
        font-family: 'Fuzzy Bubbles', cursive;
    }

    .about_bg_web{
        display: none;
    }
    .moni_video{
        position: absolute;
        top: 14rem;
        left: 6.1rem;
        width: 28rem;
    }
    .moni_teach_web{
        display: none;

    }
    .network_div_mobile{
        display: block;
    }
    .network_div_web{
        display: none;
    }
    .network_item{
        position: absolute;
        left: 6.5rem;
        top: 22rem;
        display: flex;
        height: 39.5rem;
        flex-direction: column;
    }


    .network_item_row {
        display: flex;
        flex-direction: row;
        width: 33rem;
        margin-bottom: 1rem;
        gap: 1rem;
    }
    .network_item_content{
        position: relative;
        width: 25%;
    }
    .network_item_content img{
        width: 100%;
        border-radius: 1rem;
        height: auto;
    }


    .about_bg_mobile{
        display: block;

    }
    .moni_teach_mobile{
        display: block;
        position: relative;
        width: 100%;
    }
    .moni_teach_mobile> img {
        width: 100%;
        height: auto;
        margin-top: 1rem;
        align-self: center;
    }
    .chart_div{
        position: absolute;
        margin: 0;
        top: 3.5rem;
    }
    .chart_div p{
        font-size: 3.3rem;
        margin: 0 0 1rem 0 ;
        color: black;
    }
    .chart_div img{
        width: 13rem;
        margin: 0;
    }
    .partners_div{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .partners_div a{
        width: 50%
    }
    .partners_img{
        width: 100%;
    }

    .partners h1{
        font-size: 9rem;
        font-weight: 400;
        color:black;
        margin: 0;
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin: 4rem 0;
        width: 40rem;
    }
    .cloud1 {
        position: absolute;
        width : 15rem;
        top: -3rem;
        left: -4rem;
    }

    .cloud2 {
        position: absolute;
        width : 15rem;
        right: -1rem;
        top: -4rem;
    }

    .cloud3 {
        position: absolute;
        width : 13rem;
        left: -8rem;
        top:11rem;
    }

    .cloud4 {
        position: absolute;
        width : 13rem;
        right: -5rem;
        top:10rem;
    }

    .contentCloud1{
        right: -3rem;
        transform: translateY(20rem);
    }
    .contentCloud2{
        left: 3rem;
        transform: translateY(115rem);
    }
    .contentCloud3{
        /*buy cloud*/

        /*left: 5rem;*/
        /*width: 40rem;*/
        /*transform: translateY(245rem);*/
        display: none;
    }
    .contentCloud4{
        left: -6rem;
        transform: translateY(286rem);
    }
    .contentCloud5{
        display: none;
    }
    .contentCloud6{
        display: none;
    }
    .swap img{
        margin-bottom: 10rem;
    }
    .swap p{
       font-size: 10rem;
    }
    .moni_text{
        font-size: 1rem;
    }

    .menu a img {
        width: 0;
    }


    .content {
        margin: 0;
    }


    .hidden_text {
        font-size: 0.875rem;
    }

    .token_title{
        font-size: 7rem;
        margin: 0;
    }

    .moni_teach_content{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        width: 30rem;
        top: 7rem;
        left: 3rem;

    }
    .moni_teach_content>p{
        font-size: 2rem;
        margin-left: 2.8rem;
    }

    .chart{
       display: none;
    }
    .learn_more{

    }
    .network_title {
        font-size: 9rem;
    }

    .meme_title {
        font-size: 11rem;
    }
    .game_title {
        font-size: 8rem;
        font-weight: 400;
        margin: 0 0 5rem 0;
        color: black;
    }
    .network img {
        width: 100%;
    }

    .meme_grid {
        max-height: 30rem;
        padding: 1rem;
        scrollbar-width: none; /* Firefox */
    }

    .footer_div {
        position: absolute;
        display: inline-flex;
        white-space: nowrap;
        flex-direction: row;
        height: 8rem;

        -webkit-animation: marquee 15s linear infinite;
        animation: marquee 15s linear infinite;
    }
    .footer_txt{
        float: left;
        white-space: nowrap;
        align-items: center;
        margin: 3rem 0 0 0;
        color: #62A460;
        font-family: 'Gochi Hand', cursive;
        font-size: 3rem;
    }
    @-webkit-keyframes marquee {
        0% { left: -148%; }
        100% { left: -396%; }
    }

    @keyframes marquee {
        0% { left: -148%; }

        100% { left: -396%; }

    }



}
@media (max-width: 500px) {


    .cloud1{
        left: -6rem;
    }
    .cloud2{
        right: -4rem;
    }

    .meme_grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 1rem;
        max-height: 500px;
        overflow-y: auto;
        margin: 2rem 0 2rem 0;
    }
}
